import React, { Fragment, ReactNode } from "react";
import { Text, Flex, Box, Link } from "flicket-ui";
import { startsWith, includes, intersection } from "lodash";
import { useRouter } from "next/router";

import styled from "styled-components";

import { Icon, Logo } from "~components";
import {
  useIsMobile,
  usePermissions,
  useUser,
  useSuperAdmin,
  useOrganization,
} from "~hooks";
import {
  Permission,
  Role,
  IntegrationGroup,
  IntegrationType,
  MeQuery,
} from "~graphql/sdk";
import { useIntegrations } from "~graphql";
import { OrganizationFeatures } from "~lib/features";
import { hasRoles } from "~context";
import {
  CalendarBlank,
  ChartBar,
  ChartPieSlice,
  Gear,
  Gift,
  House,
  IdentificationBadge,
  Lock,
  MapTrifold,
  Megaphone,
  Money,
  PuzzlePiece,
  Receipt,
  Robot,
  Ticket,
  Users,
  IconProps as IconPropsPhosphor,
} from "@phosphor-icons/react";

const ICON_SIZE: IconPropsPhosphor["size"] = "24";
const ICON_WEIGHT: IconPropsPhosphor["weight"] = "light";

type NavItemsProps = {
  hasPermissions: (requiredPermissions: Permission | Permission[]) => boolean;
  hasFeature: (feature: string) => boolean;
  user: MeQuery["me"];
};

type Item = {
  label: string;
  url: string;
  icon: JSX.Element;
  isActiveCheck?: (url: string) => boolean;
};

type NavLinkProps = {
  icon: JSX.Element;
  url: string;
  isActive: boolean;
  children: ReactNode;
};

const Menu = styled(Flex)`
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;

  padding: 0 8px;

  height: 80px;
  width: 100%;
  background-color: white;

  @media (min-width: ${(p) => p.theme.breakpoints.xs}) {
    position: relative;
    bottom: auto;
    left: auto;

    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 32px;

    height: auto;

    background-color: unset;
  }
`;

const NavItem = styled(Flex).attrs<{ active: boolean }>((p) => ({
  className: p.active ? "active" : undefined,
}))<{ active: boolean }>`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 64px;
  border-radius: ${(p) => p.theme.radii.xs};
  transition: all 0.12s ease-out;
  cursor: pointer;
  color: ${(p) => p.theme.colors.N800};
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: 400;
  position: relative;

  .icon-hover,
  .icon-normal {
    transition: all 0.12s ease-out;
    position: absolute;
    top: 12px;
  }
  .icon-hover {
    opacity: 0;
  }

  &:active,
  &.active {
    background-color: rgba(0, 0, 0, 0.06);
    color: ${(p) => p.theme.colors.N800};
    font-weight: 600;
  }

  &:hover:not(&.active) {
    /* background-color: rgba(0, 0, 0, 0.06); */
    font-weight: 600;
    color: ${(p) => p.theme.colors.N800};

    .icon-hover {
      opacity: 1;
    }

    .icon-normal {
      opacity: 0;
    }
  }
`;

const Nav = styled.nav`
  display: flex;
  padding: 8px 0;
  position: fixed;
  left: 0;
  top: 0;
  z-index: ${(p) => p.theme.zIndices.banner};
  overflow-y: visible;

  @media (min-width: ${(p) => p.theme.breakpoints.xs}) {
    flex-direction: column;
    align-items: center;
    overflow-y: auto;

    width: 100px;
    height: 100%;
    background-color: white;

    box-shadow: 0px 0.598509px 2.59354px rgba(0, 0, 0, 0.0525061),
      0px 2.01027px 8.71116px rgba(0, 0, 0, 0.0774939),
      0px 9px 39px rgba(0, 0, 0, 0.13);

    &.is-superadmin {
      padding-top: 80px;
    }
  }
`;

const NavLink = ({ icon: baseIcon, url, isActive, children }: NavLinkProps) => {
  const isMobile = useIsMobile("xs");

  const icon = !isActive
    ? baseIcon
    : React.cloneElement(baseIcon, {
        weight: "fill",
      });
  return (
    <Link
      to={url}
      noHoverEffect
      width="100%"
      display="flex"
      maxWidth={isMobile ? "88px" : "none"}
    >
      <NavItem active={isActive}>
        <Icon icon={icon} mb={"1/4"} className="icon-normal" />
        <Icon
          icon={React.cloneElement(baseIcon, {
            weight: "fill",
          })}
          mb={"1/4"}
          className="icon-hover"
        />
        <Text fontSize={1} mt={3}>
          {children}
        </Text>
      </NavItem>
    </Link>
  );
};

const MOBILE_ITEMS = ({ hasPermissions, user }: NavItemsProps) =>
  new Array<Item>(
    hasPermissions(Permission.ReportingFinancial) &&
      !hasRoles(user?.roles, [Role.EventManager]) && {
        label: "Home",
        url: "/",
        icon: <House size={ICON_SIZE} weight={ICON_WEIGHT} />,
        isActiveCheck: (url) => url === "/",
      },
    hasPermissions(Permission.EventUpdate) && {
      label: "Events",
      url: "/events",
      icon: <CalendarBlank size={ICON_SIZE} weight={ICON_WEIGHT} />,
      isActiveCheck: (url) => startsWith(url, "/events"),
    },
    hasPermissions(Permission.BroadcastCreate) && {
      label: "Broadcast",
      url: "/broadcast",
      icon: <Megaphone size={ICON_SIZE} weight={ICON_WEIGHT} />,
      isActiveCheck: (url) => startsWith(url, "/broadcast"),
    },
    hasPermissions(
      Permission.ReportingFinancial || Permission.ReportingScans
    ) && {
      label: "Reporting",
      url: "/reports",
      icon: <ChartBar size={ICON_SIZE} weight={ICON_WEIGHT} />,
      isActiveCheck: (url) => startsWith(url, "/reports"),
    }
  )?.filter((s) => !!s);

export const NAV_ITEMS = ({
  hasPermissions,
  user,
  hasFeature,
}: NavItemsProps) =>
  new Array<Item>(
    hasPermissions(Permission.ReportingFinancial) &&
      !hasRoles(user?.roles, [Role.EventManager]) && {
        label: "Home",
        url: "/",
        icon: <House size={ICON_SIZE} weight={ICON_WEIGHT} />,
        isActiveCheck: (url) => url === "/",
      },
    hasPermissions(Permission.EventUpdate) && {
      label: "Events",
      url: "/events",
      icon: <CalendarBlank size={ICON_SIZE} weight={ICON_WEIGHT} />,
      isActiveCheck: (url) => startsWith(url, "/events"),
    },
    hasPermissions(Permission.MembershipRead) && {
      label: "Memberships",
      url: "/memberships",
      icon: <IdentificationBadge size={ICON_SIZE} weight={ICON_WEIGHT} />,
      isActiveCheck: (url) => startsWith(url, "/memberships"),
    },
    hasPermissions(Permission.SeasonRead) && {
      label: "Seasons",
      url: "/seasons",
      icon: <MapTrifold size={ICON_SIZE} weight={ICON_WEIGHT} />,
      isActiveCheck: (url) => startsWith(url, "/seasons"),
    },
    hasFeature(OrganizationFeatures.Points) &&
      hasPermissions(Permission.PointsRead) && {
        label: "Points",
        url: "/points",
        icon: <Gift size={ICON_SIZE} weight={ICON_WEIGHT} />,
        isActiveCheck: (url) => startsWith(url, "/points"),
      },
    hasPermissions(Permission.OrderRead) &&
      !(includes(user?.roles, Role.PosAdmin) && user?.roles.length === 1) && {
        label: "Orders",
        url: "/orders?status=Completed&status=Paid",
        icon: <Ticket size={ICON_SIZE} weight={ICON_WEIGHT} />,
        isActiveCheck: (url) => startsWith(url, "/orders"),
      },
    hasPermissions(Permission.CustomerRead) &&
      !user?.roles.includes(Role.SalesOutlet) && {
        label: "Customers",
        url: "/customers",
        icon: <Users size={ICON_SIZE} weight={ICON_WEIGHT} />,
        isActiveCheck: (url) => startsWith(url, "/customers"),
      },
    hasPermissions(Permission.BroadcastCreate) && {
      label: "Broadcast",
      url: "/broadcast",
      icon: <Megaphone size={ICON_SIZE} weight={ICON_WEIGHT} />,
      isActiveCheck: (url) => startsWith(url, "/broadcast"),
    },
    hasPermissions(Permission.ReportingScans) && {
      label: "Reporting",
      url: "/reports",
      icon: <ChartBar size={ICON_SIZE} weight={ICON_WEIGHT} />,
      isActiveCheck: (url) => startsWith(url, "/reports"),
    },
    hasPermissions(Permission.PosCreate) &&
      !hasFeature(OrganizationFeatures.TempPOSOperators) && {
        label: "POS Access",
        url: "/pos/access",
        icon: <Lock size={ICON_SIZE} weight={ICON_WEIGHT} />,
        isActiveCheck: (url) => startsWith(url, "/pos/access"),
      },
    hasPermissions(Permission.PosCreate) && {
      label: "Point of sale",
      url: "/pos",
      icon: <Money size={ICON_SIZE} weight={ICON_WEIGHT} />,
      isActiveCheck: (url) => url === "/pos",
    },
    hasPermissions(Permission.PosCreate) && {
      label: "POS Reporting",
      url: "/pos/reporting",
      icon: <Receipt size={ICON_SIZE} weight={ICON_WEIGHT} />,
      isActiveCheck: (url) => startsWith(url, "/pos/reporting"),
    },
    hasPermissions(Permission.ReportingFinancial) && {
      label: "Marketing",
      url: "/marketing",
      icon: <PuzzlePiece size={ICON_SIZE} weight={ICON_WEIGHT} />,
      isActiveCheck: (url) => startsWith(url, "/marketing"),
    },
    hasPermissions(Permission.ScoutRead) &&
      hasFeature(OrganizationFeatures.Scout) && {
        label: "Scout",
        url: "/scout",
        icon: <ChartPieSlice size={ICON_SIZE} weight={ICON_WEIGHT} />,
        isActiveCheck: (url) => startsWith(url, "/scout"),
      },
    hasPermissions(Permission.AutomationRead) && {
      label: "Automation",
      url: "/automation",
      icon: <Robot size={ICON_SIZE} weight={ICON_WEIGHT} />,
      isActiveCheck: (url) => startsWith(url, "/automation"),
    }
  )?.filter((s) => !!s);

const SUPERADMIN_ITEMS: Item[] = [
  {
    label: "Accounts",
    url: "/accounts",
    icon: <CalendarBlank size={ICON_SIZE} weight={ICON_WEIGHT} />,
    isActiveCheck: (url) => startsWith(url, "/accounts"),
  },
];

export const Navbar = () => {
  const { user } = useUser();
  const router = useRouter();
  const isMobile = useIsMobile("xs");
  const { hasPermissions } = usePermissions();
  const { isSuperAdmin, isSuperAdminDomain } = useSuperAdmin();
  const { hasFeature } = useOrganization();
  const noMarketingRoles = [
    Role.SalesOutlet,
    Role.VenueAdmin,
    Role.EventManager,
  ];
  const marketingEnabled =
    intersection(user?.roles, noMarketingRoles).length > 0
      ? false
      : useIntegrations({
          group: IntegrationGroup.Marketing,
        })?.integrations?.filter((integration) => {
          return integration.type === IntegrationType.MarketingDatorama;
        })?.length > 0;

  const navItems =
    isSuperAdmin && isSuperAdminDomain
      ? SUPERADMIN_ITEMS
      : isMobile
      ? MOBILE_ITEMS({ hasPermissions, hasFeature, user })
      : NAV_ITEMS({ hasPermissions, user, hasFeature });

  return (
    <Nav className={isSuperAdmin && !isSuperAdminDomain ? "is-superadmin" : ""}>
      <Box p={"24px 8px" as any} display={{ _: "none", xs: "block" }}>
        <Link to="/" noHoverEffect={true}>
          <Logo />
        </Link>
      </Box>

      <Menu>
        {navItems.map(({ url, label, icon, isActiveCheck }) => {
          return url.valueOf() === "/marketing" ? (
            marketingEnabled ? (
              <Fragment key={url}>
                <NavLink
                  url={url}
                  icon={icon}
                  isActive={isActiveCheck(router.pathname)}
                >
                  {label}
                </NavLink>
              </Fragment>
            ) : null
          ) : (
            <Fragment key={url}>
              <NavLink
                url={url}
                icon={icon}
                isActive={isActiveCheck(router.pathname)}
              >
                {label}
              </NavLink>
            </Fragment>
          );
        })}

        {!isMobile &&
          !isSuperAdminDomain &&
          hasPermissions(Permission.OrganizationSettings) && (
            <>
              <NavLink
                url="/settings"
                icon={<Gear size={ICON_SIZE} weight={ICON_WEIGHT} />}
                isActive={startsWith(router?.pathname, "/settings")}
              >
                Settings
              </NavLink>
            </>
          )}
      </Menu>
    </Nav>
  );
};

import { X } from "@phosphor-icons/react";
import {
  DialogContent as ReachDialogContent,
  DialogOverlay as ReachDialogOverlay,
} from "@reach/dialog";
import { pick } from "@styled-system/props";
import { Box, Flex, system, SystemProps, Text } from "flicket-ui";
import { AnimatePresence, motion } from "framer-motion";
import { noop } from "lodash";
import { createContext, ReactNode, useContext } from "react";
import styled, { css } from "styled-components";

import HoverIcon from "~features/generalAdmissionEvent/components/common/HoverIcon/HoverIcon";
import {
  modalBackdropMotion,
  modalContentMotion,
} from "~lib/helpers/animation/modal";

const MOBILE_BREAKPOINT = "sm";

const MotionOverlay = motion.custom(ReachDialogOverlay);
const MotionDialog = motion.custom(ReachDialogContent);

const ModalContext = createContext<{
  close?: (...args: unknown[]) => unknown;
}>({ close: undefined });

const DialogOverlay = styled(MotionOverlay)<{
  $alignModalTop: boolean;
}>`
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  z-index: ${(p) => p.theme.zIndices.modal};
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;

  @media (min-width: ${(p) => p.theme.breakpoints[MOBILE_BREAKPOINT]}) {
    align-items: ${(p) => (p.$alignModalTop ? " flex-start" : "center")};
    padding: ${(p) => p.theme.space[4]}px;
  }
`;

const DialogContent = styled(MotionDialog)<SystemProps>`
  padding: 0;
  width: 100%;
  margin: 0;
  position: relative;
  background: transparent;

  @media (min-width: ${(p) => p.theme.breakpoints[MOBILE_BREAKPOINT]}) {
    width: 774px;
  }

  && {
    ${system}
  }
`;

const ContentWrapper = styled.div<SystemProps & { $small?: boolean }>`
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  background: ${(p) => p.theme.colors.white};
  outline: none;
  width: 100%;
  height: 100dvh;

  ${(p) => (p.$small ? `padding: ${p.theme.space[3]}px;` : "")}

  @media (min-width: ${(p) => p.theme.breakpoints[MOBILE_BREAKPOINT]}) {
    margin: 64px 0;
    border-radius: ${(p) => p.theme.radii.sm};
    box-shadow: ${(p) => p.theme.shadows.sm};
    height: auto;
    max-height: 80dvh;
  }

  && {
    ${system}
  }
`;

type ModalProps = SystemProps<{
  isOpen: boolean;
  close: (any) => void;
  ariaLabel?: string;
  children: ReactNode;
  hasDivider?: boolean;
  small?: boolean;
  alignModalTop?: boolean;
  dialogContentProps?: SystemProps;
  modalBaseProps?: SystemProps;
  clickOutsideToClose?: boolean;
}>;

export const ModalBaseWrapper = ({
  children,
  ariaLabel = "modal",
  isOpen,
  close,
  small = false,
  alignModalTop = false,
  dialogContentProps = {},
  ...props
}: ModalProps) => (
  <AnimatePresence>
    {isOpen && (
      <DialogOverlay
        {...modalBackdropMotion}
        onDismiss={close}
        $alignModalTop={alignModalTop}
      >
        <DialogContent
          {...modalContentMotion}
          aria-label={ariaLabel}
          width={small ? "470px" : undefined}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          {...pick(dialogContentProps)}
        >
          <ContentWrapper
            id="modal-content-wrapper"
            $small={small}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            {...pick(props)}
          >
            {children}
          </ContentWrapper>
        </DialogContent>
      </DialogOverlay>
    )}
  </AnimatePresence>
);

const CustomModal = ({
  children,
  ariaLabel,
  isOpen,
  close,
  clickOutsideToClose = false,
  small,
  ...props
}: ModalProps) => (
  <ModalContext.Provider value={{ close }}>
    <ModalBaseWrapper
      close={clickOutsideToClose ? close : noop}
      isOpen={isOpen}
      ariaLabel={ariaLabel}
      small={small}
      {...props}
    >
      {children}
    </ModalBaseWrapper>
  </ModalContext.Provider>
);

function Header({
  children,
  small,
}: {
  children: ReactNode;
  small?: boolean;
  close?: () => void;
}) {
  const { close } = useContext(ModalContext);
  return (
    <Box
      px={4}
      py={4}
      d="flex"
      position="relative"
      alignItems="center"
      justifyContent="space-between"
      borderBottomColor="N200"
      borderBottomStyle="solid"
      css={css({
        // Don't know why this property is not working with typescript on the component 🤷
        borderBottomWidth: "1px",
        lineHeight: 1,
      })}
    >
      {children && (
        <Text fontWeight="heavy" fontSize={small ? 4 : 6}>
          {children}
        </Text>
      )}

      <HoverIcon
        icon={<X size={20} />}
        onClick={close}
        w="40px"
        h="40px"
        position="absolute"
        right="19px"
      />
    </Box>
  );
}

function Content({ children }: { children: ReactNode; small?: boolean }) {
  return (
    <Box fontSize={4} overflowY="auto" flex={1} p={3} px={4}>
      {children}
    </Box>
  );
}

// eslint-disable-next-line react/display-name
function Footer({ children }: { children: ReactNode }) {
  return (
    <Box
      px={4}
      py={3}
      backgroundColor={"white"}
      zIndex={1}
      borderTopStyle="solid"
      borderTopColor="N200"
      css={css({
        // Don't know why this property is not working with typescript on the component 🤷
        borderTopWidth: "1px",
      })}
    >
      <Flex justifyContent="flex-end">{children}</Flex>
    </Box>
  );
}

CustomModal.Footer = Footer;
CustomModal.Header = Header;
CustomModal.Content = Content;

export default CustomModal;

import { GraphQLError } from "graphql";
import { CreateEventInput, UpdateEventInput } from "~graphql/sdk";

import { useRouter } from "next/router";
import { useSDK } from "~hooks";
import { getError, showToast } from "~lib/helpers";

export default function useEventMutations<TData>({
  eventId,
  mutate,
}: {
  eventId: string;

  // Note: upgrading SWR gives us the KeyedMutator type
  mutate: (
    data?: TData,
    shouldRevalidate?: boolean
  ) => Promise<TData | undefined>;
}) {
  const sdk = useSDK();
  const router = useRouter();

  const createEvent = async (input: CreateEventInput) => {
    try {
      const res = await sdk.createEvent({ input });
      return res.createEvent.id;
    } catch (err) {
      showErrorToast(err);
    }
  };

  const updateEvent = async (
    input: UpdateEventInput,
    sendTicketViewableEmails?: boolean
  ) => {
    try {
      await sdk.updateEvent({
        id: eventId,
        input,
        sendTicketViewableEmails,
      });

      const res = await mutate(undefined, true);

      showToast("Successfully updated event");

      return res;
    } catch (err) {
      showErrorToast(err);
    }
  };

  const duplicateEvent = async () => {
    try {
      const res = await sdk.duplicateEvent({ id: eventId });

      showToast("Event duplicated!", "success");
      await router.push(
        "/events/[id]",
        `/events/${res.duplicateEvent}?tab=general`
      );
    } catch (err) {
      showErrorToast(err);
    }
  };

  return {
    createEvent,
    updateEvent,
    duplicateEvent,
  };
}

function showErrorToast(err: unknown) {
  showToast(getError(err as GraphQLError, "graphQL"), "error");
}

import React, { FC } from "react";
import { theme } from "flicket-ui";
import { ThemeProvider } from "styled-components";
import { BaseProvider, UserProvider, FormStateProvider } from "~context";
import { AlertManagerProvider } from "~context/AlertManager";
import { CurrentOrganizationOutput } from "~graphql/sdk";
import { ReCaptchaProvider } from "~context/RecaptchaContext";
import { POSAuthProvider } from "~context/POSAuthContext";
import { ScreenSizeProvider } from "~context/ScreenSizeContext";

interface ProvidersProps {
  organization?: CurrentOrganizationOutput | undefined;
  setOrganization?: (org: any) => void;
}

export const Providers: FC<ProvidersProps> = ({
  children,
  setOrganization,
  organization,
}) => {
  const recaptchaKey = process.env.RECAPTCHA_SITE_KEY;

  const themeWithOverrides = {
    ...theme,
    colors: {
      ...theme.colors,
      P300: "#F06F05",
      P200: "#f06f0550",
      P100: "#f06f0525",
      error: "#DC3009",
      error25: "#fdf5f3",
      error50: "#DC3009",
      error100: "#DC3009",
      N800: "#1A1A1A",
      N700: "#404040",
      N600: "#666666",
      N500: "#999999",
      N400: "#B2B2B2",
      N300: "#CCCCCC",
      N200: "#E5E5E5",
      N100: "#F2F2F2",
    },
    fonts: {
      ...theme.fonts,
      body: "Figtree, sans-serif",
      heading: "Figtree, sans-serif",
    },
  };

  return (
    <BaseProvider organization={organization} setOrganization={setOrganization}>
      <ThemeProvider theme={themeWithOverrides}>
        <ScreenSizeProvider>
          <AlertManagerProvider>
            <ReCaptchaProvider reCaptchaKey={recaptchaKey}>
              <UserProvider>
                <FormStateProvider>
                  <POSAuthProvider>{children}</POSAuthProvider>
                </FormStateProvider>
              </UserProvider>
            </ReCaptchaProvider>
          </AlertManagerProvider>
        </ScreenSizeProvider>
      </ThemeProvider>
    </BaseProvider>
  );
};

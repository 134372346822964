import { useEffect, useState } from "react";
import { ActivePromotion, BuyType, GetType, Promotion, Seat } from "./types";

export const useMultiBuy = (promotions: Promotion[], seats: Seat[]) => {
  const [activePromotions, setActivePromotions] = useState<ActivePromotion[]>(
    []
  );

  useEffect(() => {
    if (promotions && seats.length > 0) {
      let hasActivePromotions = false;
      promotions.forEach((promo) => {
        let getType: GetType, buyType: BuyType;
        if ("getTicketType" in promo) {
          getType = promo?.getTicketType;
          buyType = promo?.buyTicketType;
        } else if ("getMembershipType" in promo) {
          getType = promo?.getMembershipType;
          buyType = promo?.buyMembershipType;
        }

        const buySeats = seats?.filter(
          ({ ticketType, membershipType }) =>
            (ticketType?.id || membershipType?.id || ticketType) === buyType.id
        );

        const getSeats = seats?.filter(
          ({ ticketType, membershipType }) =>
            (ticketType?.id || membershipType?.id || ticketType) === getType.id
        );

        const buyTotal = buySeats?.reduce(
          (acc, curr) => acc + curr.quantity,
          0
        );

        const buySeatQty = Math.floor(buyTotal / promo.buyQuantity);

        const getTotal = getSeats?.reduce(
          (acc, curr) => acc + curr.quantity,
          0
        );

        const getSeatQty = Math.ceil(getTotal / promo.getQuantity);

        const giftedQty =
          buySeatQty * promo.getQuantity <= getTotal
            ? buySeatQty * promo.getQuantity
            : getTotal;

        const foundPromo = activePromotions.find(
          ({ promotion }) => promotion.id === promo.id
        );

        if (getSeatQty > 0 && buySeatQty > 0) {
          hasActivePromotions = true;
          if (foundPromo) {
            if (
              (foundPromo.buySeatQty !== buySeatQty &&
                foundPromo.getSeatQty !== getSeatQty) ||
              foundPromo.giftedQty !== giftedQty
            ) {
              setActivePromotions([
                ...activePromotions.filter(
                  ({ promotion }) => promotion.id !== promo.id
                ),
                {
                  promotion: promo,
                  giftedQty,
                  getType,
                  getSeatQty,
                  buySeatQty,
                },
              ]);
            }
          } else {
            setActivePromotions([
              ...activePromotions,
              {
                promotion: promo,
                giftedQty,
                getType,
                getSeatQty,
                buySeatQty,
              },
            ]);
          }
        }
      });

      // remove promotions if no longer valid
      if (!hasActivePromotions) {
        setActivePromotions([]);
      }
    }
  }, [promotions, seats]);

  return { activePromotions };
};

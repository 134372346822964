import React, { useEffect, useState } from "react";
import axios from "axios";
import { Flex, Text } from "flicket-ui";
import objectFitImages from "object-fit-images";
import { toast } from "react-toastify";
import { SWRConfig } from "swr";
import { setLocale } from "yup";

import { Loader, Providers, SuperAdminTopBar } from "~components";
import { apiUrl } from "~config";
import { sdk } from "~lib";
import { isSuperAdminDomain, handlePromise, getError } from "~lib/helpers";
import CSSreset from "~styles/CSSreset";

import "swiper/css/swiper.css";
import "react-toastify/dist/ReactToastify.css";
import "../../public/static/fonts/stylesheet.css";
import { i18n } from "~lib/i18n";
import { useThirdpartyScripts } from "~features/analytics/useThirdpartyScripts";

axios.defaults.baseURL = apiUrl;
axios.defaults.withCredentials = true;

setLocale({
  mixed: {
    required: "This field is required",
    notType: "Invalid value",
  },
  string: {
    email: "Invalid email address",
  },
});

toast.configure({
  position: "bottom-right",
  autoClose: 4000,
  closeButton: false,
  hideProgressBar: true,
});

const getCurrentOrganization = async () =>
  handlePromise(async () => sdk().currentOrganization());

const App = ({ Component, pageProps, props }) => {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<any>(null);

  useThirdpartyScripts();

  const loadOrganization = async () => {
    const [errorRes, res] = await getCurrentOrganization();

    if (errorRes || !res?.currentOrganization) {
      setError(getError(errorRes, "graphQL"));
    } else {
      i18n.timezone = res.currentOrganization.timezone;
      setData(res);
      setLoading(false);
    }
  };

  useEffect(() => {
    objectFitImages();
  }, []);

  const hostname =
    typeof window !== "undefined" ? window?.location?.hostname : undefined;

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (isSuperAdminDomain()) {
        setLoading(false);
      } else if (isLoading) {
        loadOrganization();
      }
    }
  }, [hostname]);

  let content;

  if (error) {
    content = (
      <Flex minH="100vh" variant="center" flexDir="column">
        <Text variant="title.M">Error loading organization</Text>
        <Text variant="title.XS">{error}</Text>
      </Flex>
    );
  } else if (isLoading) {
    content = <Loader />;
  } else {
    content = <Component {...pageProps} {...props} />;
  }

  return (
    <Providers
      organization={data?.currentOrganization}
      setOrganization={setData}
    >
      <SWRConfig
        value={{
          fetcher: async (url, params) =>
            axios(url, { params }).then((res) => res.data),
          revalidateOnFocus: false,
        }}
      >
        <CSSreset />
        <SuperAdminTopBar />
        {content}
      </SWRConfig>
    </Providers>
  );
};

export default App;

import { Flex } from 'flicket-ui';
import styled from 'styled-components';

export const StickyHeader = styled(Flex)`
  position: sticky;
  top: 0;
  z-index: 10;
  justify-content: space-between;
  align-items: center;
  min-height: 84px;
  transition: background 0.2s ease-in-out;

  &.stuck {
    background: white;
    box-shadow: 0px 1.85406px 0.85785px rgba(0, 0, 0, 0.00843437),
      0px 4.45557px 2.06153px rgba(0, 0, 0, 0.0121168),
      0px 8.38944px 3.88168px rgba(0, 0, 0, 0.015),
      0px 14.9653px 6.92426px rgba(0, 0, 0, 0.0178832),
      0px 27.991px 12.9511px rgba(0, 0, 0, 0.0215656);
  }
`;

import { addDays, subDays } from "date-fns";
import { intersection } from "lodash";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import {
  EventsListUseParamsQuery,
  MembershipsListUseParamsQuery,
  OrderChannel,
  PointReportingFilterSource,
  ReleaseType,
  ReportingFilterSource,
  ReportingFinancialSalesBreakdownSource,
  Role,
  TicketSoldTime,
} from "~graphql/sdk";
import {
  EventsListUseParamsDocument,
  MembershipsListUseParamsDocument,
  ReleasesListUseParamasDocument,
} from "~graphql/typed-document-nodes";
import { useUser } from "~hooks";
import { formatDate } from "~lib/helpers";
import {
  mapParamsToQuery,
  mapQueryToParams,
  QueryParams,
} from "~lib/helpers/reportSales";
import { useQuery } from "../../../hooks/useQuery";

const CHANNEL_OPTIONS = [
  { label: "All channels", value: undefined },
  { label: "Online", value: OrderChannel.Online },
  { label: "Back office", value: OrderChannel.BackOffice },
  { label: "POS", value: OrderChannel.Pos },
];

export interface DateParams {
  dates: Date[];
  startDate: Date;
  endDate: Date;
}

export interface ParamsProps extends DateParams {
  channel: OrderChannel;
  source:
    | ReportingFilterSource
    | ReportingFinancialSalesBreakdownSource
    | PointReportingFilterSource;
  sourceId?: string;
  releaseId?: string;
  status?: string[];
  orderBy?: any;
  gateway?: string;
  seller?: string;
  excludeAllReleases?: boolean;
  release?: string;
  ready?: boolean;
}

export type ExcludeProps<T extends string> = {
  [key in T]?: ParamsExcludeProps;
};

type ParamsExcludeProps = {
  [key in keyof Partial<ParamsProps>]: boolean;
} & { point?: boolean };

export const useFilterParams = (args?: {
  initialParams?: Partial<ParamsProps>;
  exclude?: ParamsExcludeProps;
  excludeAllEvents?: boolean;
  excludeAllMemberships?: boolean;
  excludeAllReleases?: boolean;
  excludeRestrictedEvents?: boolean;
  // If you want the url to be updated when params change
  syncQueryParams?: boolean;
  // set to true if we only want to view the data
  // used to disable unexpected data update
  viewOnly?: boolean;
  // add point to source
  enablePointSource?: boolean;
  isSalesOutlet?: boolean;
}) => {
  const pointSourceOptions = Object.keys(PointReportingFilterSource).map(
    (key) => ({
      label: key,
      value: PointReportingFilterSource[key] as PointReportingFilterSource,
    })
  );

  const sorceOptions = Object.keys(ReportingFilterSource).map((key) => ({
    label: key,
    value: ReportingFilterSource[key] as ReportingFilterSource,
  }));

  const SOURCE_OPTIONS =
    args?.enablePointSource && !args?.exclude?.point
      ? pointSourceOptions
      : sorceOptions;

  const router = useRouter();
  const { user, isEventManager } = useUser();
  const {
    exclude,
    initialParams,
    excludeAllEvents,
    excludeAllMemberships,
    excludeAllReleases,
    excludeRestrictedEvents,
    syncQueryParams,
  } = args || {};

  const [firstLoad, setFirstLoad] = useState(true);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const [params, _setParams] = useState<ParamsProps>({
    channel: null,
    ...(!exclude?.source && { source: ReportingFilterSource.Overall }),
    ...(!exclude?.dates && {
      dates: [subDays(today, 31), addDays(today, 1)],
      startDate: subDays(today, 31),
      endDate: addDays(today, 1),
    }),
    ready: false,
    ...initialParams,
    ...(syncQueryParams && mapQueryToParams(router.query as QueryParams)),
  });

  const getSourceId = (params: ParamsProps) =>
    params.source === ReportingFilterSource.Event
      ? eventOptions?.[0]?.value
      : params.source === ReportingFilterSource.Membership
      ? membershipOptions?.[0]?.value
      : null;

  const setParams = (newParams: ParamsProps) => {
    if (syncQueryParams) {
      // The purpose of this is to figure out the default sourceId
      // when the user changes the dropdown option.
      if (newParams.source !== params.source) {
        newParams.sourceId = getSourceId(newParams);
      }

      // remove dates if sourceId is changing so that we
      // can default to the start and end dates for tickets sold
      // see the useEffect that handles this below.
      if (
        newParams.sourceId &&
        params.sourceId &&
        newParams.sourceId !== params.sourceId
      ) {
        newParams.dates = null;
        newParams.startDate = null;
        newParams.endDate = null;
      }
    }

    _setParams(newParams);

    if (syncQueryParams) {
      void router.push({
        query: mapParamsToQuery(newParams),
      });
    }
  };

  const { data: eventsData, error: errorEvents } = useQuery(
    EventsListUseParamsDocument,
    {
      where: {
        isOutlet: args?.isSalesOutlet,
        isActive: null,
        restrictedUserId: isEventManager ? user?.id : undefined,
      },
    }
  );

  const eventsAccessFilter = (
    events: EventsListUseParamsQuery["events"]["edges"]
  ): EventsListUseParamsQuery["events"]["edges"] => {
    if (
      !user?.roles.includes(Role.EventReporting) ||
      !excludeRestrictedEvents
    ) {
      return events;
    }

    return events?.filter((event) =>
      user?.accessEventIds?.includes(event.node.id)
    );
  };

  const events = eventsAccessFilter(eventsData?.events.edges)
    ?.map((event) => event?.node)
    .sort((a, b) => {
      return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
    });

  const eventOptions = [
    !excludeAllEvents && {
      label: "All events",
      value: undefined,
    },
    ...(events?.map((event) => ({
      label: `${formatDate(new Date(event.startDate), "dd-MM-yyyy")} | ${
        event?.title
      }`,
      value: event?.id,
    })) || []),
  ]?.filter((e) => !!e);

  const { data: releaseData, error: errorReleases } = useQuery(
    params.sourceId && ReleasesListUseParamasDocument,
    {
      event: params.sourceId,
    }
  );

  let releases = releaseData?.releases;

  if (args?.isSalesOutlet) {
    releases = releases?.filter(
      (release) => release.type === ReleaseType.Outlet
    );
  }

  const membershipAccessFilter = (
    memberships: MembershipsListUseParamsQuery["memberships"]["edges"]
  ): MembershipsListUseParamsQuery["memberships"]["edges"] => {
    if (
      !user?.roles.includes(Role.EventReporting) ||
      !excludeRestrictedEvents
    ) {
      return memberships;
    }

    return memberships?.filter((membership) =>
      user?.accessMembershipIds?.includes(membership?.node?.id)
    );
  };

  const { data: membershipsData, error: errorMemberships } = useQuery(
    !exclude?.source &&
      !excludeAllMemberships &&
      MembershipsListUseParamsDocument,
    {
      where: { isActive: null },
    }
  );
  const memberships = membershipAccessFilter(membershipsData?.memberships.edges)
    ?.map(({ node }) => node)
    .sort((a, b) => {
      return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
    });

  let ticketSoldTime: TicketSoldTime;
  if (
    params.source === ReportingFilterSource.Event &&
    params.sourceId &&
    events?.length > 0
  ) {
    ticketSoldTime = events.find((event) => event.id === params.sourceId)
      ?.ticketSoldTime;
  }

  if (
    params.source === ReportingFilterSource.Membership &&
    params.sourceId &&
    memberships?.length > 0
  ) {
    ticketSoldTime = memberships.find(
      (membership) => membership.id === params.sourceId
    )?.ticketSoldTime;
  }

  const releaseOptions = [
    !excludeAllReleases && {
      label: "All releases",
      value: "",
    },
    ...(releases?.map((release) => ({
      label: release?.name,
      value: release?.id,
    })) || []),
  ]?.filter((r) => !!r);

  const membershipOptions = [
    !excludeAllMemberships && {
      label: "All memberships",
      value: undefined,
    },
    ...(memberships?.map((membership) => ({
      label: membership?.name,
      value: membership?.id,
    })) || []),
  ]?.filter((m) => !!m);

  useEffect(() => {
    if (!ticketSoldTime) {
      setParams({
        ...params,
        dates: [subDays(today, 31), addDays(today, 1)],
        startDate: subDays(today, 31),
        endDate: addDays(today, 1),
      });
      return;
    }

    const hasQueryParamDates = !!(
      syncQueryParams &&
      router.query.startDate &&
      router.query.endDate &&
      firstLoad
    );

    if (hasQueryParamDates) {
      setFirstLoad(false);
      return;
    }

    if (ticketSoldTime?.startTime && ticketSoldTime?.endTime) {
      setParams({
        ...params,
        dates: [
          subDays(new Date(ticketSoldTime?.startTime), 1),
          addDays(new Date(ticketSoldTime?.endTime), 1),
        ],
        startDate: subDays(new Date(ticketSoldTime?.startTime), 1),
        endDate: addDays(new Date(ticketSoldTime?.endTime), 1),
      });
    } else {
      setParams({
        ...params,
        dates: [subDays(today, 31), addDays(today, 1)],
        startDate: subDays(today, 31),
        endDate: addDays(today, 1),
      });
    }
  }, [ticketSoldTime?.sourceId]);

  const readyForDisplay = (params: ParamsProps): boolean => {
    if (
      params.source === ReportingFilterSource.Overall ||
      params.source === PointReportingFilterSource.Package
    ) {
      return true;
    } else if (params.source === ReportingFilterSource.Event) {
      if (
        (params.sourceId && releases && events) ||
        (!params.sourceId && events)
      ) {
        return true;
      }
      return false;
    } else if (params.source === ReportingFilterSource.Membership) {
      if (memberships) {
        return true;
      }
      return false;
    }
  };

  const ready = readyForDisplay(params);

  useEffect(() => {
    if (ready) {
      setParams({ ...params, ready });
    }
  }, [ready]);

  const hasError: boolean =
    !!errorEvents || !!errorReleases || !!errorMemberships;

  const timeHasLoaded = (): boolean => {
    if (
      (params.source === ReportingFilterSource.Event ||
        params.source === ReportingFilterSource.Membership) &&
      params.sourceId
    ) {
      return !!ticketSoldTime;
    }
    return true;
  };

  return {
    eventOptions,
    membershipOptions,
    releaseOptions,
    events,
    errorEvents,
    releases,
    errorReleases,
    memberships,
    errorMemberships,
    params,
    setParams,
    channelOptions: CHANNEL_OPTIONS,
    sourceOptions: SOURCE_OPTIONS,
    exclude,
    ready,
    hasError,
    timeHasLoaded,
  };
};

export type ParamsMethods = ReturnType<typeof useFilterParams>;

import { Flex, SystemProps, Text, system } from "flicket-ui";
import {
  CSSProperties,
  Dispatch,
  ElementType,
  FC,
  HTMLAttributes,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import styled, { DefaultTheme } from "styled-components";
import { Icon, IconProps } from "../Icon/Icon";
import {
  CheckCircle,
  Copy,
  PencilSimple,
  Prohibit,
  Trash,
} from "@phosphor-icons/react";

const Button = styled(Flex).attrs({
  as: "button",
  py: "6/4",
  color: "N700",
  fontSize: 2,
  lineHeight: "18px" as any,
  fontWeight: "extraBold",
})<
  SystemProps & {
    onClick: () => void;
    arrowColor?: keyof DefaultTheme["colors"];
  }
>`
  border-radius: 6px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #fff;
  height: auto;
  padding-left: ${(p) => p.theme.space[2]}px;
  padding-right: ${(p) => p.theme.space[2]}px;

  box-shadow: 0px 4px 29px rgba(0, 0, 0, 0.07),
    0px 1.6711px 12.1155px rgba(0, 0, 0, 0.0503198),
    0px 0.893452px 6.47753px rgba(0, 0, 0, 0.0417275),
    0px 0.500862px 3.63125px rgba(0, 0, 0, 0.035),
    0px 0.266004px 1.92853px rgba(0, 0, 0, 0.0282725),
    0px 0.11069px 0.802504px rgba(0, 0, 0, 0.0196802);

  cursor: pointer;
  user-select: none;

  transition: background 0.2s, box-shadow 0.15s, border-color 0.2s, color 0.2s;

  &:after {
    content: "";
    width: 0;
    height: 0;
    border-top: 4px solid
      ${(p) => (p.arrowColor ? p.arrowColor : p.theme.colors.P300)};

    border-left: 4px solid transparent;
    border-right: 4px solid transparent;

    margin-left: ${(p) => p.theme.space["6/4"]}px;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  &:active {
    box-shadow: none;
  }

  ${system}
`;

const Options = styled(Flex).attrs({
  as: "ul",
  flexDir: "column",
  bg: "white",
  p: 1,
  borderRadius: "sm",
})`
  position: absolute;
  top: calc(100% + 4px);
  list-style-type: none;
  white-space: nowrap;
  width: fit-content;
  min-width: 100%;
  box-shadow: 0px 9px 39px rgba(0, 0, 0, 0.08),
    0px 2.01027px 8.71116px rgba(0, 0, 0, 0.0575),
    0px 0.598509px 2.59354px rgba(0, 0, 0, 0.0325);

  transition: all 0.2s ease;
  z-index: 3;
`;

const Option = styled(Text).attrs((p) => ({
  as: "button",
  py: "12px",
  pr: "12px",
  width: 1,
  bg: "white",
  borderRadius: "xs",
  fontSize: 2,
  fontWeight: "medium",
  lineHeight: 1,
}))<{ hasIcon?: boolean }>`
  cursor: pointer;
  transition: background 0.2s ease;

  &:hover {
    background-color: ${(p) => p.theme.colors.P100};
  }

  padding-left: ${(p) => (p.hasIcon ? 34 : 12)}px;
`;

export type DropdownOption = {
  label: string;
  onClick: () => any;
  icon?: IconProps["icon"];
  type?: "edit" | "delete" | "copy" | "enable" | "disable";
};

export interface DropdownButtonProps {
  isActive?: boolean;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  style?: IDropdown["dropdownButtonStyle"];
  arrowColor?: IDropdown["arrowColor"];
}
interface IDropdown {
  options: DropdownOption[];
  align?: string;
  dropdownButtonStyle?: CSSProperties;
  optionsStyle?: CSSProperties;
  arrowColor?: keyof DefaultTheme["colors"];
  DropdownButton?: ElementType;
}

export const Dropdown: FC<
  IDropdown & SystemProps & HTMLAttributes<HTMLDivElement>
> = ({
  options,
  align = "right",
  children,
  dropdownButtonStyle,
  optionsStyle,
  arrowColor,
  DropdownButton: DropdownButtonProps,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLElement>(null);

  const handleClick = (e: Event) => {
    // dont show any drop down if there are no options
    if (options?.length > 0) {
      setIsOpen(dropdownRef?.current?.contains(e.target as HTMLElement));
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => document.removeEventListener("click", handleClick);
  }, []);

  const DropdownButton = DropdownButtonProps ?? Button;

  return (
    <Flex
      flexDir="column"
      position="relative"
      width="fit-content"
      {...props}
      ref={dropdownRef}
      flexShrink={0}
    >
      <DropdownButton
        style={dropdownButtonStyle}
        arrowColor={arrowColor}
        isActive={isOpen}
        setIsOpen={setIsOpen}
      >
        {children}
      </DropdownButton>

      {isOpen && (
        <Options
          left={align === "left" && 0}
          right={align === "right" && 0}
          style={optionsStyle}
        >
          {options.map(({ label, onClick, icon: maybeIcon, type }) => {
            const icon = maybeIcon ?? getIconFromType(type);

            return (
              <li key={label} data-testid="dropdown-option">
                <Option
                  onClick={(e) => {
                    e.stopPropagation();
                    onClick();
                    setIsOpen(false);
                  }}
                  position="relative"
                  hasIcon={!!icon}
                >
                  {icon && (
                    <Icon
                      icon={icon}
                      mr={1}
                      fontSize={"18px" as any}
                      position="absolute"
                      top="10px"
                      left="6px"
                    />
                  )}
                  {label}
                </Option>
              </li>
            );
          })}
        </Options>
      )}
    </Flex>
  );
};

// Helper function to map dropdown option types to icons for visual consistency
function getIconFromType(type: DropdownOption["type"]) {
  switch (type) {
    case "copy":
      return <Copy />;
    case "delete":
      return <Trash />;
    case "edit":
      return <PencilSimple />;
    case "enable":
      return <CheckCircle />;
    case "disable":
      return <Prohibit />;
  }
}

import { HTMLProps } from "react";
import { Box } from "flicket-ui";

import styled, { DefaultTheme } from "styled-components";

interface IToggle extends HTMLProps<HTMLInputElement> {
  h?: number;
  w?: number;
  gutter?: number;
  on?: keyof DefaultTheme["colors"];
  off?: keyof DefaultTheme["colors"];
  checked: boolean;
}

const size = (h: number, gutter: number) => `${h - gutter * 2}px`;

export const Toggle = styled(Box).attrs({
  as: "input",
  type: "checkbox",
  borderRadius: "full",
})<IToggle>`
  appearance: none;
  position: relative;

  width: ${(p) => `${p.w || 28}px`};
  height: ${(p) => `${p.h || 16}px`};

  background-color: ${(p) =>
    p.checked
      ? p.theme.colors[p.on || "P300"]
      : p.theme.colors[p.off || "N500"]};
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.15);
  transition: all 0.2s ease;

  cursor: pointer;

  &:after {
    content: "";
    border-radius: ${(p) => p.theme.radii.full};
    height: ${(p) => size(p.h || 16, p.gutter || 2)};
    width: ${(p) => size(p.h || 16, p.gutter || 2)};
    background-color: white;
    position: absolute;
    top: ${(p) => `${p.gutter || 2}px`};
    left: ${(p) => `${p.gutter || 2}px`};
    transform: ${(p) => (p.checked ? "translateX(100%)" : "translateX(0)")};
    transition: all 0.2s ease;
  }

  &:checked {
    &:after {
      transform: translateY("100%");
      transition: all 0.2s ease;
    }
  }
`;

import useSWR from "swr";
import { ReleasesOptionsInput } from "~graphql/sdk";
import { useSDK } from "~hooks";

export const useReleases = (
  eventId: string,
  options?: ReleasesOptionsInput
) => {
  const sdk = useSDK();

  const { data, ...methods } = useSWR(
    eventId ? ["releases", eventId] : null,
    async (_: string, eventId: string) =>
      sdk
        .releases({
          event: eventId,
          options,
        })
        .then((res) => res.releases)
  );

  return { data, ...methods };
};

import { useContext } from "react";
import { BaseContext } from "~context";
import { UpdateOrganizationInput } from "~graphql/sdk";
import { showToast, getError, sdk } from "~lib";
import { OrganizationFeatures } from "~lib/features";

export const useOrganization = () => {
  const ctx = useContext(BaseContext);

  if (ctx === undefined) {
    throw new Error("Context must be used within a provider!");
  }

  const handleImage = (image) => {
    if (image === null || image instanceof File) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return image;
    }

    if (image.target?.value === null) {
      return null;
    }

    return undefined;
  };

  const updateOrganization = async (input: UpdateOrganizationInput) => {
    let updateOrganizationInput = input;
    if (input.branding) {
      const { branding, ...rest } = input;

      const {
        background,
        fullBackground,
        logo,
        mobileLogo,
        favicon,
        ...restBranding
      } = branding;

      updateOrganizationInput = {
        ...rest,
        branding: {
          ...restBranding,
          background: handleImage(background),
          fullBackground: handleImage(fullBackground),
          logo: handleImage(logo),
          mobileLogo: handleImage(mobileLogo),
          favicon: handleImage(favicon),
        },
      };
    }

    try {
      const res = await sdk({
        orgId: ctx?.organization?.id,
      }).updateOrganization({
        input: updateOrganizationInput,
      });

      ctx?.setOrganization({ currentOrganization: res.updateOrganization });
      showToast("Successfully updated organisation");
    } catch (e) {
      showToast(getError(e, "graphQL"), "error");
    }
  };

  const hasFeature = (feature: OrganizationFeatures): boolean =>
    ctx?.organization?.features?.includes(feature);

  return { organization: ctx?.organization, updateOrganization, hasFeature };
};

import { SystemProps, system } from "flicket-ui";
import styled from "styled-components";

export const Tr = styled.tr<SystemProps>`
  cursor: pointer;
  transition: all 0.12s ease-out;

  &:hover:not(:has(.hover-child:hover)) {
    background-color: ${({ theme }) => theme.colors.N100}7a;
  }

  ${system}
`;

export const Td = styled.td<SystemProps>`
  padding: 12px;

  ${system}
`;
